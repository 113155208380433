import http from '@/utils/http'

/** ******** 总览看板 **********/
// 左1：总览视图-产品年月日产量数据
export function getProductionValueByUple (p) {
  return http.get('/material/dataIndex/getProductionValueByUple', p)
}

// 左2 + 中3: 产能效率 + 完成率
export function getProductionRateByUple (p) {
  return http.get('/material/dataIndex/getProductionRateByUple', p)
}

// 左3: 总览视图-产品产量月度数据对比数据
export function getMonthCompareProductionRateByUple (p) {
  return http.get('/material/dataIndex/getMonthCompareProductionRateByUple', p)
}

// 表格上方数据统计
export function getTotalCompVehiDataIndex (p) {
  return http.get('/material/dataIndex/getTotalCompVehiDataIndex', p)
}

// 右1 + 右2
export function getTotalCompVehiCount (p) {
  return http.get('/material/dataIndex/getTotalCompVehiCount', p)
}

// 右3
export function getTotalVehiOverSpeedAndWarnAndProjCount (p) {
  return http.get('/material/dataIndex/getTotalVehiOverSpeedAndWarnAndProjCount', p)
}

// 中2：表格 总览视图-日/月/年产量
export function getTotalDayProductionValueByUple (p) {
  return http.get('/material/dataIndex/getTotalDayProductionValueByUple', p)
}

// 中2：表格 总览视图-产能效率排名, cityId 市id countrId 区县id provinceId 省id
export function getUpleProductionRateRankData (p) {
  return http.get('/material/dataIndex/getUpleProductionRateRankData', p)
}

/** ******** 砂浆，混凝土 **********/
// 左1数据看板-产量数据(混凝土、砂浆)
export function getUpleProductionValueByType (p) {
  return http.get('/material/dataIndex/getUpleProductionValueByType', p)
}

// 左3 月产量数据对比、产品标号数据对比、产能效率对比(混凝土、砂浆)
export function getCompareMonthUpleData (p) {
  return http.get('/material/dataIndex/getCompareMonthUpleData', p)
}

// 中表格 数据看板-日月年产量数据(混凝土、砂浆)
export function getUpleProductionValueDate (p) {
  return http.get('/material/dataIndex/getUpleProductionValueDate', p)
}

// 企业分布
export function getCompMapData (p) {
  return http.get('/material/dataIndex/getCompMapData', p)
}

// 重点项目
export function getProjMapData (p) {
  return http.get('/material/dataIndex/getProjMapData', p)
}

// 车辆分布
export function getVehiMapData (p) {
  return http.get('/material/dataIndex/getVehiMapData', p)
}

// 区域产量
export function getAreaWeightMapData (p) {
  return http.get('/material/dataIndex/getAreaProductionValueByUple', p)
}

// 商砼、砂浆、水泥看板-图表数据，右侧及中间数据
export function getProductionVehiTable (p) {
  return http.get('/material/dataIndex/getProductionVehiTableIndex', p)
}

// 商砼、砂浆、水泥看板-车辆企业数据，左侧及中间数据
export function getProductionVehiData (p) {
  return http.get('/material/dataIndex/getProductionVehiDataIndex', p)
}

// 砂浆看板-获取项目数据和砂浆罐个数，右侧表格
export function getMortarProjAndPot (p) {
  return http.get('/material/dataIndex/getMortarProjAndPotIndex', p)
}

// 混凝土看板-获取近三年的项目数据，右侧表格
export function getConcreteYearProjData (p) {
  return http.get('/material/dataIndex/getConcreteYearProjData', p)
}

// 车辆监测-地图上方企业、车辆数据
export function getVehiData (p) {
  return http.get('/material/dataIndex/getVehiDataIndex', p)
}

// 车辆监测-左侧折线图今日上线率数据（车辆整点上线率和企业上线率）
export function getVehiOnlineRate (p) {
  return http.get('/material/dataIndex/getVehiOnlineRate', p)
}

// 车辆看板-今日数据（今日超速次数和今日驾驶行为分析）;左下和右上角的组件
export function getVehiTodayActData (p) {
  return http.get('/material/dataIndex/getVehiTodayActData', p)
}

// 车辆看板-排行榜数据（车辆数排名、路线排名、单车里程排行）;中下表格
export function getVehiRankData (p) {
  return http.get('/material/dataIndex/getVehiRankData', p)
}

// 车辆看板-报警数据（近30天报警总数和本月企业单车报警排名）;右下两个组件
export function getVehiWarnData (p) {
  return http.get('/material/dataIndex/getVehiWarnData', p)
}

// 产能效率-混凝土、砂浆
// cityId 市id
// countrId 区县id
// provinceId 省id
// type 类型 1 混凝土 2 砂浆
export function getProductionTypeRateRankData (p) {
  return http.get('/material/dataIndex/getProductionTypeRateRankData', p)
}
