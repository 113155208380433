<template>
  <div class="chart-bar-box">
    <div ref="myChart" class="my-chart" />
    <div class="title-line">
      <span class="prefix-title" />
      {{ title }}
    </div>
    <span v-if="unit" class="unit-line">单位：{{ unit }}</span>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    unit: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      myChart: null,
      bgcolors: [
        ['#2FDAD0', '#2FA3DA'],
        ['#FFE785', '#F5A773']
      ]
    }
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      handler (nVal) {
        if (!nVal) return
        this.$nextTick(() => {
          this.myChart.setOption(this.getOption(nVal))
        })
      }
    }
  },
  mounted () {
    const myChart = this.myChart = echarts.init(this.$refs.myChart, null, { renderer: 'svg' })

    this.chartResize(myChart)
  },
  methods: {
    // echart 注册resize事件
    chartResize (myChart) {
      const debounced = XEUtils.debounce(myChart.resize, 500)
      window.addEventListener('resize', debounced) // 监听浏览器窗口变化重新加载
      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('resize', debounced)
      })
    },
    getSeries (data, bgcolors) {
      return data.map((item, index) => {
        const newItem = {
          name: item.name,
          type: 'bar',
          barWidth: 13,
          barMinHeight: 2,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
              offset: 0,
              color: bgcolors[index][0] // 0% 处的颜色
            }, {
              offset: 1,
              color: bgcolors[index][1] // 60% 处的颜色
            }], false)
          },
          data: item.value.map(item => item <= 0 ? '' : item)
        }
        return newItem
      })
    },
    getOption ({ xAxis, yAxis }) {
      return {
        xAxis: [{
          data: xAxis,
          type: 'category',
          axisTick: { show: true },
          axisLine: {
            show: true, // 隐藏X轴轴线
            lineStyle: { color: '#556168' }
          },
          splitLine: {
            show: true,
            lineStyle: { color: '#083049' }
          },
          axisLabel: { color: '#fff' }
        }],
        yAxis: [{
          type: 'value',
          axisTick: { show: true },
          axisLine: {
            show: true,
            color: '#556168'
          },
          splitLine: {
            show: true,
            lineStyle: { color: '#083049' }
          },
          axisLabel: { color: '#C7C9CA' },
          splitNumber: 4
        }],
        grid: {
          left: '6%', // 图表距边框的距离
          right: '6%',
          bottom: '5%',
          top: '32%',
          containLabel: true
        },
        series: this.getSeries(yAxis, this.bgcolors),
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' },
          formatter: (data) => {
            let name = ''
            const res = data.map((item) => {
              if (!name) name = item.name
              return item.seriesName + '：' + (item.value || 0) + this.unit
            }).join('<br>')
            // 'seriesName', 'name', 'value'
            return name + '<br>' + res
          }
          // formatter: '{b}' + yAxis.map((item, i)=>  `<br/>{a${i}}：{c${i}}${this.unit||''}`).join(''),
        },
        legend: {
          data: yAxis.map(({ name }) => name),
          top: '8%',
          right: '4%',
          textStyle: {
            color: '#fff',
            fontSize: 11, // 文字的字体大小。
            lineHeight: 12
          },
          itemWidth: 12, // 图例标记的图形宽度。
          itemHeight: 12 //  图例标记的图形高度。
        }
      }
    }
  }
  // components: { ChartExtend },
}
</script>

<style lang="scss" scoped>
.chart-bar-box {
  position: relative;
}
.title-line {
  position: absolute;
  top: 10%;
  left: 3%;
  color: #fff;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .prefix-title {
    display: block;
    width: 6px;
    height: 6px;
    background: #00ffff;
    border-radius: 50%;
    margin-right: 5px;
  }
}
.unit-line {
  position: absolute;
  width: 70px;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  color: #c7c9ca;
  font-size: 12px;
}
.my-chart {
  width: 100%;
  height: 100%;
}
</style>
