<template>
  <div class="scorllBox" :style="{height: height + 'px'}">
    <div class="scrollnameList">
      <div v-for="(item, index) in titles" :key="index">{{ item.name }}</div>
    </div>

    <div v-if="isDynamic" @mouseenter="onTopEnter" @mouseleave="onTopLeave">
      <swiper
        ref="mySwiper"
        v-if="!reload"
        class="sm-content seamless-warp"
        :options="swiperOption()"
        :style="{height: scorllHeight + 'px'}"
      >
        <swiper-slide
          class="sm-li scroll_item"
          v-for="(item, idx) in data"
          :key="idx">
          <div v-for="titleObj in titles" :key="titleObj.prop" :title="item[titleObj.prop]">
            {{item[titleObj.prop]}}
          </div>
        </swiper-slide>
      </swiper>
    </div>

    <div v-else>
      <template v-if="data.length > 0">
        <div
          class="sm-li scroll_item"
          v-for="(item, idx) in data"
          :key="idx">
          <div
            v-for="titleObj in titles"
            :key="titleObj.prop"
            :title="item[titleObj.prop]"
          >
            {{item[titleObj.prop]}}
          </div>
        </div>
      </template>
      <div v-else class="empty-desc" :style="{height: scorllHeight + 'px'}">暂无数据</div>
    </div>
  </div>
</template>

<script>
// 循环滚动
import { swiper, swiperSlide } from 'vue-awesome-swiper'
require('swiper/dist/css/swiper.css')

export default {
  components: {
    swiper,
    swiperSlide
  },
  props: {
    titles: {
      default: () => {
        return []
      }
    },
    height: {
      type: Number,
      default: 160
    },
    data: {
      default: () => []
    }
  },
  watch: {
    data (v, o) {
      if (v != o) {
        this.reloadTable()
      }
    }
  },
  data () {
    return {
      reload: false
    }
  },
  // 计算属性，获得可以操作的swiper对象
  computed: {
    scorllHeight () {
      return this.height - 34
    },
    isDynamic () {
      return this.scorllHeight < (this.data.length * 34)
    },
    mySwiper () {
      // mySwiper 是要绑定到标签中的ref属性
      return this.$refs.mySwiper.swiper
    }
  },
  methods: {
    reloadTable () {
      this.reload = true
      this.$nextTick(() => {
        this.reload = false
      })
    },
    onTopEnter () {
      this.mySwiper.autoplay.stop()
      this.mySwiper.setTranslate(this.mySwiper.getTranslate())// 鼠标移入立刻停止滚动
    },
    onTopLeave () {
      this.mySwiper.autoplay.start()
    },
    swiperOption () {
      return {
        notNextTick: true,
        direction: 'vertical',
        speed: 1000, // 滚动速度
        autoplay: {
          delay: 1,
          disableOnInteraction: false, // 用户操作swiper之后，是否禁止autoplay
          waitForTransition: true // 等待过渡完毕。自动切换会在slide过渡完毕后才开始计时
        },
        simulateTouch: false, // 设置鼠标拖动无效
        freeMode: true, // free模式，slide会根据惯性滑动且不会贴合
        mousewheel: {
          sensitivity: 3 // 滚动一次切换几个slide
        },
        loop: true, // 循环
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper的父元素时，自动初始化swiper
        observeSlideChildren: true,
        spaceBetween: 0, // slide之间的距离（单位px）
        slidesPerView: Math.ceil(Number(this.scorllHeight) / 34), // slide可见数量，34: 行高
        createElements: true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  box-sizing: border-box;
}
.scorllBox {
  font-size: 12px;
  // border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  background: rgba(18, 19, 49, 0);
  overflow: hidden;
}
.seamless-warp {
  width: 100%;
  overflow: hidden;
}
.scroll_item {
  @include text-light(#00FFFF);
  // border-bottom: 1px solid #184f70;
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  cursor: pointer;
  line-height: 34px;
  height: 34px;
  box-sizing: border-box;
  div {
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 3px;
  }
}
.scrollnameList {
  background: #002C4B;
  height: 34px;
  display: flex;
  justify-content: space-evenly;
  border-bottom: 1px solid #184f70;
  align-items: center;
  text-align: center;
  div {
    flex: 1;
    height: 34px;
    line-height: 34px;
    color: #c3cbd1;
  }
}
.empty-desc {
  color: #c3cbd1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .swiper-wrapper {
  transition-timing-function:linear !important;
}
</style>
