<template>
  <div class="map-box" ref="mapBox" v-loading="loadingMap" element-loading-background="rgba(0,0,0,0.45)">
    <div class="my-chart" ref="myChart"></div>

    <div class="map-type-list">
      <!-- <div class="map-type-item">视频监控</div> -->
      <div
        class="map-type-item"
        :class="{active: activeMapTab===item.id}"
        v-for="item in mapTabs"
        :key="item.id"
        @click="changeMapType(item)"
      >{{item.name}}</div>
      <div class="map-type-item" @click="backTop">返回上级</div>
      <div class="map-type-item" @click="fullScreenMap">全屏预览</div>
    </div>

    <div class="total-card flex justify-around" v-if="totals.length > 0">
      <div class="total-item flex flex-column align-center" v-for="item in totals" :key="item.name">
        <div class="total-title">{{item.name}}</div>
        <count-to-card class="pt-11" :value="item.value" />
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import screenfull from 'screenfull'
import CountToCard from '@/components/CountToCard'
import { getCompMapData, getProjMapData, getVehiMapData, getAreaWeightMapData } from '@/apis/dataView'
import { gainRegi } from '@/apis/common'
import { formatter } from './mapName'
import axios from 'axios'
import XEUtils from 'xe-utils'
import { cancelAll } from '@/utils/http'

/**
 * 1. 地图的tab选项卡（区域产量，企业分布...）对应不同的接口，用 activeMapTab 控制
 * 2. 不同的模块，请求接口时需要传 type: 0总览; 1混凝土; 2砂浆; 3水泥; 0车辆
 */
export default {
  props: {
    totals: { type: Array, default: () => [] },
    type: { type: Number }
  },
  data () {
    return {
      myChart: null,
      activeMapLevels: [], // 激活的地图层级，全国 > 省 > 市 > 区/县
      loadingMap: false,
      isFullscreen: false,
      mapTabs: [],
      activeMapTab: undefined
    }
  },
  watch: {
    activeMapLevels: {
      handler () {
        cancelAll()
        this.getRequestParams().then((params) => {
          this.$emit('area-change', params)
        })
      }
    }
  },
  created () {
    // 初始化地图tabs
    this.initMapTabs()
    // 初始化地图区域层级
    this.activeMapLevels = this.getMapLevels()
    // 获取区域层级对应的请求参数
    this.$nextTick(() => {
      // 初始化地图
      const myChart = this.myChart = echarts.init(this.$refs.myChart)
      this.chartResize(myChart) // 监听尺寸变化重新渲染地图
      this.renderMap()
      // 监听地图点击事件(地图下钻), 修改
      myChart.on('click', (ev) => {
        // 记录当前地图层级
        const activeMapLevels = this.activeMapLevels
        if (ev.data && (activeMapLevels[activeMapLevels.length - 1].code !== ev.data.code)) {
          this.activeMapLevels.push(ev.data)
          this.renderMap()
        }
      })
    })
  },
  methods: {
    initMapTabs () {
      this.activeMapTab = 2 // 默认是区域产量
      const tabs = [
        { name: '区域产量', id: 2 },
        { name: '企业分布', id: 3 },
        { name: '车辆分布', id: 4 }
      ]
      // 总览才有重点项目
      if (this.$route.fullPath.includes('dataView/index')) {
        tabs.push({ name: '重点项目', id: 1 })
      }
      this.mapTabs = tabs
    },
    // 初始化地图当前地图的区域 为 账号的areaCode对应的区域
    getMapLevels () {
      const {
        areaType,
        regiProvinCode,
        regiProvinId,
        regiCityCode,
        regiCityId,
        regiCountrCode,
        regiCountrId
      } = this.$store.state.account.userInfo
      // 默认就是全国
      const activeMapLevels = [{ name: '全国', code: 100000, id: undefined }]
      if (areaType == 2) { // 省
        activeMapLevels.push({ code: regiProvinCode, id: regiProvinId })
      }
      if (areaType == 3) { // 市
        activeMapLevels.push({ code: regiProvinCode, id: regiProvinId })
        activeMapLevels.push({ code: regiCityCode, id: regiCityId })
      }
      if (areaType == 4) { // 区县
        activeMapLevels.push({ code: regiProvinCode, id: regiProvinId })
        activeMapLevels.push({ code: regiCityCode, id: regiCityId })
        activeMapLevels.push({ code: regiCountrCode, id: regiCountrId })
      }
      return activeMapLevels
    },
    // 渲染地图
    renderMap () {
      const activeMapLevels = this.activeMapLevels
      const areaCode = activeMapLevels[activeMapLevels.length - 1].code
      let coordinate
      let areaInfo
      let geoJSON
      // 获取区域编码对应的geoJSON数据
      const p1 = axios.get('/map/' + areaCode + '.json').then((res) => {
        geoJSON = res.data
      })
      // 获取地图要展示的数据
      const p2 = this.getMapData().then((data) => {
        coordinate = data.coordinate
        areaInfo = data.areaInfo
      })
      Promise.all([p1, p2]).then(() => {
        const mapName = areaCode === 100000 ? 'china' : 'map'
        echarts.registerMap(mapName, geoJSON)
        const option = this.getOption(geoJSON, { coordinate, areaInfo }, areaCode, mapName)
        this.myChart.setOption(option, true)
      }).catch((err) => {
        console.error('地图初始化异常', err)
      })
    },
    getUsefulData ({ coordinate, areaInfo }, geoJson) {
      const labelData = []
      let max = 0
      let min = 0
      const mapData = geoJson.features.map(({ properties }, index) => {
        const currCode = properties.adcode // 城市code
        const centroid = properties.centroid || properties.center // 区域中间的经纬度
        const { value, id, valueList } = areaInfo.find(({ code }) => code == currCode) || { value: 0 }
        // 有中心位置 且 value 大于 0 才展示统计城市数量的 label
        if (centroid && value > 0) {
          labelData.push({
            name: properties.name,
            value: centroid.concat(value)
          })
        }
        // 当前地图value的最大值, 最小值
        if (value > max) max = value
        if (index === 0 || (index !== 0 && value < min)) min = value
        return {
          id,
          value,
          valueList,
          name: properties.name,
          code: properties.adcode,
          center: properties.centroid
        }
      })
      // 修复南海诸岛的数据
      if (this.activeMapLevels.length === 1) {
        const target = mapData.find((item) => item.name == '南海诸岛')
        !target && mapData.push({ name: '南海诸岛', value: 0 })
      }
      return {
        mapData,
        labelData,
        coordinate,
        max: Math.ceil(max),
        min: Math.floor(min)
      }
    },
    getOption (geoData, data, cityCode, mapName) {
      const { mapData, labelData, max, min, coordinate } = this.getUsefulData(data, geoData)
      const newMax = max < 3 ? 3 : max
      const option = {
        visualMap: {
          show: true,
          type: 'piecewise',
          left: '10',
          bottom: '100',
          text: ['高', '低'], // 文本，默认为数值文本
          calculable: true,
          seriesIndex: [0],
          itemHeight: 10,
          itemWidth: 12,
          showLabel: true,
          precision: 0,
          pieces: [ // 按照 3, 4 , 3 来分
            { min: newMax * 7 / 10, max: newMax },
            { min: newMax * 3 / 10, max: newMax * 7 / 10 },
            { min: min, max: newMax * 3 / 10 }
          ],
          textStyle: {
            color: '#fff',
            fontSize: 10
          },
          inRange: {
            color: ['#002C4C', '#E89100', '#D21715'] // 蓝、橙、红
          }
        },
        // 车辆分布不展示tooltip
        tooltip: this.activeMapTab == 4 ? { show: false } : { trigger: 'item' },
        geo: {
          // map: 'china' | '四川'
          // 必须要先引入了对应地图的js文件或者json文件，在这一步的时候，echarts会自动将对应的JS文件注入，地图才会显示.
          map: mapName,
          show: true,
          roam: true, // 不开启缩放和平移
          zoom: 1.3, // 视角缩放比例
          label: {
            formatter,
            show: true,
            color: 'rgb(249, 249, 249)', // 省份标签字体颜色
            fontSize: '11px'
          },
          itemStyle: {
            areaColor: '#031525',
            borderWidth: 1, // 设置外层边框
            borderColor: '#01A2FE'
          }
        },
        legend: {
          icon: 'roundRect',
          data: ['区域数量'],
          bottom: '30',
          left: 10,
          textStyle: {
            color: '#fff',
            fontSize: 11, // 文字的字体大小。
            lineHeight: 12
          },
          itemWidth: 12, // 图例标记的图形宽度。
          itemHeight: 12 //  图例标记的图形高度。
        },
        series: [
          {
            type: 'map',
            geoIndex: 0,
            shadowColor: '#3B5077',
            data: mapData,
            tooltip: {
              formatter: ({ data: { name, value, valueList } }) => {
                const units = { 2: '方', 3: '家企业', 4: '辆车', 1: '个重点项目' }
                const unit = units[this.activeMapTab]
                if (this.activeMapTab == 2 && valueList) { // 区域产量
                  return valueList.reduce((str, curr) => {
                    return str + `<br>${curr.key}：${curr.value + unit}`
                  }, name)
                } else {
                  return `${name}：${value + unit}`
                }
              }
            }
          },
          {
            // name: '散点',
            type: 'scatter',
            coordinateSystem: 'geo',
            data: coordinate,
            symbolSize: coordinate.length > 1000 ? 2 : 5,
            large: true, // 开启大量数据优化
            tooltip: { show: false }
          },
          this.activeMapTab == 2 ? undefined : {
            name: '区域数量',
            type: 'scatter',
            data: labelData,
            coordinateSystem: 'geo',
            geoIndex: 0,
            symbol: 'pin', // 气泡
            symbolSize: function (val) {
              const len = (String(val[2])).length
              return len * 10 + 12
            },
            label: {
              formatter: '{@[2]}',
              show: true,
              color: '#fff',
              fontSize: 10
            },
            // 标志颜色
            itemStyle: { color: '#00A2FF' },
            zlevel: 6,
            tooltip: { show: false }
          }
        ]
      }
      // 由于海南地图包括南海及南海诸岛在内的大片区域，所以显示海南地图时，要将地图放大，并设置海南岛居中显示
      if (cityCode == 100000) {
        option.geo.center = [109.844902, 19.0392]// 中心位置
        option.geo.layoutCenter = ['55%', '98%']// 地图相对容器偏移
        option.geo.layoutSize = '98%'// 地图放大比例
        option.geo.zoom = 1.3
      } else if (cityCode == 460000) {
        option.geo.center = [109.844902, 19.0392]// 中心位置
        option.geo.layoutCenter = ['50%', '40%']// 地图相对容器偏移
        option.geo.layoutSize = '380%'// 地图放大比例
        option.geo.zoom = 1
      } else { // 非显示海南时，将设置的参数恢复默认值
        option.geo.center = undefined
        option.geo.layoutCenter = undefined
        option.geo.layoutSize = '90%'// 地图放大比例
        option.geo.zoom = 1
      }
      return option
    },

    async getRequestParams () {
      // 1. 获取请求参数 params
      const activeMapLevels = this.activeMapLevels
      const index = activeMapLevels.length - 1
      const propNames = [undefined, 'provinceId', 'cityId', 'countrId']
      const propName = propNames[index]
      // 这里打个补丁，因为后端有时不会返回区域的数据，取不到区域编码在数据库中对应的id，所以如果没有id，拿省市区的接口换一下
      let id = activeMapLevels[index].id
      const code = activeMapLevels[index].code
      // 如果 index !== 0, 0 说明是全国
      if (!id && index !== 0) {
        const fields = [undefined, 'regiProvinId', 'regiCityId', 'regiCountrId']
        const { data: targetAreaData } = await gainRegi({
          regiLevel: index,
          regiCodes: [code]
        }, false)
        id = targetAreaData[0][fields[index]]
      }

      let params = {}
      if (propName) params = { [propName]: id }
      return params
    },

    // 获取地图要展示的数据
    async getMapData () {
      // 1. 获取请求参数 params
      const params = await this.getRequestParams()
      // 2. 获取参数type，type: 0 总览; 1 商砼; 2 砂浆; 3 水泥; 5 车辆 (总览和车辆调用接口不需要传type)
      const type = this.type == 5 ? undefined : this.type
      // 3. 请求数据
      this.loadingMap = true
      const mapDataApi = this.mapDataApi()
      const res = await mapDataApi({ ...params, type })
      this.loadingMap = false
      const data = res.data || {}
      // 经纬度数据
      const coordinate = (data.coordinateList || []).map((item) => {
        return item.split(',').map(subItem => Number(subItem))
      })
      // 区域数据
      let areaInfo = data.mapDistributionDtoList
      if (this.activeMapTab == 2) { // 区域产量
        areaInfo = data.weightDistributionDtoList.map((item) => {
          if (this.type > 0) { // 非总览
            item.value = item.valueList[0].value || 0
          } else {
            // 总览按照三个产量值的总和
            item.value = item.valueList.reduce((sum, { value: currVal = 0 }) => XEUtils.round(sum + currVal, 2), 0)
          }
          return item
        })
      }
      areaInfo = (areaInfo || []).map((item) => {
        item.lngLat = item.lngLat.split(',').map(subItem => Number(subItem))
        return item
      })
      return { coordinate, areaInfo }
    },

    changeMapType ({ id }) {
      this.activeMapTab = id
      // this.activeMapLevels = this.getMapLevels()
      this.renderMap()
    },

    fullScreenMap () {
      const el = this.$refs.mapBox
      if (screenfull.enabled) {
        screenfull.request(el)
      }
    },

    backTop () {
      if (this.activeMapLevels.length > 1) {
        this.activeMapLevels.pop()
        this.renderMap()
      }
    },

    mapDataApi () {
      const apis = {
        1: getProjMapData,
        2: getAreaWeightMapData,
        3: getCompMapData,
        4: getVehiMapData
      }
      return apis[this.activeMapTab]
    },

    /**
     * echart 注册resize事件
     */
    chartResize (myChart) {
      const debounced = XEUtils.debounce(myChart.resize, 500)
      window.addEventListener('resize', debounced) // 监听浏览器窗口变化重新加载
      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('resize', debounced)
      })
    }
    // getFormatter (mapData) {
    //   return ({ name }) => {
    //     console.log(mapData, name)
    //     const taget = mapData.find((item) => item.name == name) || { value: 0 }
    //     return name + '\n' + taget.value + '方'
    //   }
    // }
  },
  components: {
    CountToCard
  }
}
</script>

<style lang="scss" scoped>
.my-chart {
  width: 100%;
  height: 100%;
}
.map-box {
  position: relative;
}
.map-type-list {
  position: absolute;
  right: 0;
  bottom: 0;
  .map-type-item {
    margin-bottom: 10px;
    width: 74px;
    line-height: 26px;
    font-size: 12px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    background: linear-gradient(90deg, #009CAE 0%, rgba(1, 27, 64, 0.6) 100%);

    &.active {
      background: linear-gradient(90deg, #D60E0C 0%, rgba(1, 27, 64, 0.6) 100%);
    }
  }
}
.total-card {
  width: 100%;
  position: absolute;
  top: 0;

  .total-title {
    color: #fff;
    font-size: 12px;
    text-align: center;
  }
}
</style>
