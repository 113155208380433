<template>
  <div class="chart-bar-box">
    <chart-extend ref="chartExtent" />
    <div class="title-line">
      <span class="prefix-title" />{{ title }}
    </div>
    <span v-if="unit" class="unit-line">单位：{{ unit }}</span>
  </div>
</template>

<script>
import ChartExtend from './ChartExtend'
export default {
  components: { ChartExtend },
  props: {
    title: {
      type: String,
      default: ''
    },
    unit: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default () {
        return null
      }
    }
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      handler (nVal) {
        if (!nVal) return
        this.$nextTick(() => {
          const { myChart } = this.$refs.chartExtent
          myChart.setOption(this.getOption(nVal))
        })
      }
    }
  },
  methods: {
    getOption ({ yAxis, xAxis }) {
      const option = {
        xAxis: [
          {
            data: xAxis,
            type: 'category',
            axisTick: { show: true },
            axisLine: {
              show: true, // 隐藏X轴轴线
              lineStyle: { color: '#556168' }
            },
            splitLine: {
              show: true,
              lineStyle: { color: '#083049' }
            },
            axisLabel: {
              color: '#fff'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisTick: { show: true },
            axisLine: {
              show: true,
              color: '#556168'
            },
            splitLine: {
              show: true,
              lineStyle: { color: '#083049' }
            },
            axisLabel: { color: '#C7C9CA' }
          }
        ],
        grid: {
          left: '5%', // 图表距边框的距离
          right: '5%',
          bottom: '3%',
          top: '30%',
          containLabel: true
        }
      }

      if (yAxis.length > 0 && typeof yAxis[0] == 'object') {
        option.legend = {
          data: yAxis.map(({ name }) => name),
          top: '8%',
          right: '4%',
          textStyle: {
            color: '#fff',
            fontSize: 11, // 文字的字体大小。
            lineHeight: 12
          },
          itemWidth: 12, // 图例标记的图形宽度。
          itemHeight: 12 //  图例标记的图形高度。
        }

        option.tooltip = {
          trigger: 'axis',
          axisPointer: { type: 'shadow' },
          formatter:
            '{b}' +
            yAxis
              .map((item, i) => `<br/>{a${i}}：{c${i}}${this.unit || ''}`)
              .join('')
        }

        const colors = ['#0E537D', '#1DFEFF']
        option.series = yAxis.map((item, i) => {
          return {
            name: item.name,
            type: 'pictorialBar',
            symbol: 'roundRect',
            animationDelay: (dataIndex, params) => params.index * 20, // 每个图形动画持续时间
            itemStyle: { color: colors[i] },
            z: 1,
            symbolRepeat: true, // 图形是否重复
            symbolSize: [16, 8], // 图形元素的尺寸
            symbolMargin: '1.5', // 图形垂直间隔
            data: item.value,
            animationEasing: 'elasticOut', // 动画效果
            barGap: '0%',
            barCategoryGap: '50%'
          }
        })
      }
      return option
    }
  }
}
</script>

<style lang="scss" scoped>
.chart-bar-box {
  position: relative;
}
.chart-bar {
  width: 100%;
  height: 100%;
}
.title-line {
  position: absolute;
  top: 10%;
  left: 3%;
  color: #fff;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .prefix-title {
    display: block;
    width: 6px;
    height: 6px;
    background: #00ffff;
    border-radius: 50%;
    margin-right: 5px;
  }
}
.unit-line {
  position: absolute;
  width: 70px;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  color: #c7c9ca;
  font-size: 12px;
}
</style>
