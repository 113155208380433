<template>
  <div class="chart-bar-box">
    <chart-extend ref="chartExtent"/>
    <div class="title-line">
      <span class="prefix-title"></span>{{title}}
    </div>
    <span class="unit-line" v-if="unit">单位：{{unit}}</span>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import ChartExtend from './ChartExtend'
export default {
  props: ['title', 'unit', 'data'],
  data () {
    return {
      bgcolors: [
        ['#2FDAD0', '#2FA3DA'],
        ['#FFE785', '#F5A773']
      ]
    }
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      handler (nVal) {
        if (!nVal) return
        this.$nextTick(() => {
          const { myChart } = this.$refs.chartExtent
          myChart.setOption(this.getOption(nVal))
        })
      }
    }
  },
  methods: {
    getOption ({ yAxis, xAxis }) {
      const option = {
        xAxis: [
          {
            data: xAxis,
            type: 'category',
            axisTick: { show: true },
            axisLine: {
              show: true, // 隐藏X轴轴线
              lineStyle: { color: '#556168' }
            },
            splitLine: {
              show: true,
              lineStyle: { color: '#083049' }
            },
            axisLabel: { color: '#fff' }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisTick: { show: true },
            axisLine: {
              show: true,
              color: '#556168'
            },
            splitLine: {
              show: true,
              lineStyle: { color: '#083049' }
            },
            axisLabel: { color: '#C7C9CA' }
          }
        ],
        grid: {
          left: '5%', // 图表距边框的距离
          right: '5%',
          bottom: '3%',
          top: '30%',
          containLabel: true
        },
        series: [
          {
            data: yAxis,
            type: 'bar',
            barWidth: '16',
            label: {
              show: true,
              position: 'top',
              color: '#FFF',
              distance: 10
            },
            itemStyle: {
              barBorderRadius: [100, 100, 0, 0],
              color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [{
                offset: 0,
                color: '#51C5FD'
              }, {
                offset: 1,
                color: '#005BB1'
              }], false) // 渐变
            }
          }
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' },
          formatter: '{b}：{c}' + this.unit
        }
      }
      return option
    }
  },
  components: { ChartExtend }
}
</script>

<style lang="scss" scoped>
.chart-bar-box {
    position: relative;
}
.chart-bar {
    width: 100%;
    height: 100%;
}
.title-line {
    position: absolute;
    top: 10%;
    left: 3%;
    color: #fff;
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .prefix-title {
        display: block;
        width: 6px;
        height: 6px;
        background: #00FFFF;
        border-radius: 50%;
        margin-right: 5px;
    }
}
.unit-line {
    position: absolute;
    width: 70px;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    color: #C7C9CA;
    font-size: 12px;
}
</style>
