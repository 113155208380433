<template>
  <div class="my-chart" ref="myChart"></div>
</template>

<script>
import * as echarts from 'echarts'
import XEUtils from 'xe-utils'
export default {
  data () {
    return {
      myChart: null
    }
  },
  mounted () {
    const myChart = this.myChart = echarts.init(this.$refs.myChart)
    this.chartResize(myChart)
  },
  methods: {
    /**
     * echart 注册resize事件
     */
    chartResize (myChart) {
      const debounced = XEUtils.debounce(myChart.resize, 500)
      window.addEventListener('resize', debounced) // 监听浏览器窗口变化重新加载
      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('resize', debounced)
      })
    }
  }
}
</script>

<style scoped>
.my-chart {
    width: 100%;
    height: 100%;
}
</style>
