<template>
  <div style="position: relative;">
    <chart-extend ref="chartExtent"/>
    <div class="title-line">
      <span class="prefix-title"></span>{{title}}
    </div>
    <span class="unit-line" v-if="unit">单位：{{unit}}</span>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import ChartExtend from './ChartExtend'
import { colorRgb } from '@/utils/tools'
export default {
  props: ['title', 'unit', 'data', 'label'],
  data () {
    return {
      bgcolors: ['#00FFFF', '#F9C17B']
    }
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      handler (nVal) {
        if (!nVal) return
        this.$nextTick(() => {
          const { myChart } = this.$refs.chartExtent
          myChart.setOption(this.getOption(nVal))
        })
      }
    }
  },
  methods: {
    getOption () {
      const yAxis = this.data.yAxis
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' },
          formatter: (
            yAxis.length > 1 && typeof yAxis[0] == 'object'
              ? `{b}<br>{a0} ${this.label}：{c0}${this.unit || ''}<br>{a1} ${this.label}：{c1}${this.unit || ''}`
              : `{b}<br>${this.label}：{c}${this.unit || ''}`
          )
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisTick: { show: true },
            data: this.data.xAxis,
            axisLine: {
              show: true, // 隐藏X轴轴线
              lineStyle: { color: '#556168' }
            },
            splitLine: {
              show: true,
              lineStyle: { color: '#083049' }
            },
            axisLabel: { color: '#fff' }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisTick: { show: true },
            axisLine: {
              show: true, // 隐藏X轴轴线
              color: '#556168'
            },
            splitLine: {
              show: true,
              lineStyle: { color: '#083049' }
            },
            axisLabel: { color: '#C7C9CA' },
            splitNumber: 4
          }
        ],
        grid: {
          left: '6%', // 图表距边框的距离
          right: '6%',
          bottom: '8%',
          top: '30%',
          containLabel: true
        },
        series: this.getSeries(yAxis, this.bgcolors)
      }
      if (yAxis.length > 0 && typeof yAxis[0] == 'object') {
        option.legend = {
          icon: 'roundRect',
          data: yAxis.map(({ name }) => name),
          top: '10%',
          right: '4%',
          textStyle: {
            color: '#fff',
            fontSize: 11, // 文字的字体大小。
            lineHeight: 12
          },
          itemWidth: 12, // 图例标记的图形宽度。
          itemHeight: 12 //  图例标记的图形高度。
        }
      }
      return option
    },
    getSeries (yAxis, bgcolors) {
      /**
       * 兼容 yAxis 的 数据格式
       * 1. yAxis: [
       *   { name: '', value: [] },
       *   ...
       * ]
       * 2. yAxis: []
       */
      if (yAxis && typeof yAxis[0] == 'object') {
        return yAxis.map((item, index) => {
          return {
            name: item.name,
            data: item.value,
            type: 'line',
            smooth: false,
            symbol: 'circle',
            showSymbol: true,
            lineStyle: { width: 1 },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: colorRgb(bgcolors[index], 0.5)
              }, {
                offset: 0.9,
                color: colorRgb(bgcolors[index], 0)
              }], false),
              shadowColor: colorRgb(bgcolors[index], 0.1),
              shadowBlur: 10
            },
            itemStyle: {
              color: colorRgb(bgcolors[index], 1),
              borderColor: colorRgb(bgcolors[index], 0.27),
              borderWidth: 12
            }
          }
        })
      } else {
        return {
          data: yAxis,
          type: 'line',
          smooth: false,
          symbol: 'circle',
          showSymbol: true,
          lineStyle: { width: 1 },
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: colorRgb(bgcolors[0], 0.5)
            }, {
              offset: 0.9,
              color: colorRgb(bgcolors[0], 0)
            }], false),
            shadowColor: colorRgb(bgcolors[0], 0.1),
            shadowBlur: 10
          },
          itemStyle: {
            color: colorRgb(bgcolors[0], 1),
            borderColor: colorRgb(bgcolors[0], 0.27),
            borderWidth: 12
          }
        }
      }
    }
  },
  components: { ChartExtend }
}
</script>

<style lang="scss" scoped>
  .title-line {
    position: absolute;
    top: 10%;
    left: 3%;
    color: #fff;
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .prefix-title {
      display: block;
      width: 6px;
      height: 6px;
      background: #00FFFF;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  .unit-line {
    position: absolute;
    width: 70px;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    color: #C7C9CA;
    font-size: 12px;
  }
</style>
