<template>
  <chart-extend ref="chartExtent"/>
  <!-- <div ref="pieRef"></div> -->
</template>

<script>
import ChartExtend from './ChartExtend'
export default {
  components: { ChartExtend },
  props: {
    data: {
      type: Object,
      default () {
        return { name: '', value: 0 }
      }
    },
    // name: {
    //     type: String,
    //     default: ''
    // },
    // value: {
    //     type: Number,
    //     default: 0
    // },
    color: {
      type: String,
      default: '#52CF5A'
    }
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      handler () {
        this.$nextTick(() => {
          const { myChart } = this.$refs.chartExtent
          myChart.setOption(this.getOption())
        })
      }
    }
  },
  methods: {
    getOption () {
      const { value, title } = this.data
      return {
        title: [
          {
            text: value + '%',
            textStyle: {
              color: '#fff',
              fontSize: 18,
              fontWeight: '400'
            },
            left: 'center',
            top: '30%'
          },
          {
            text: title,
            textStyle: {
              color: '#fff',
              fontSize: 12,
              fontWeight: '400'
            },
            left: 'center',
            bottom: '10'
          }
        ],
        angleAxis: {
          max: 100,
          clockwise: false,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        radiusAxis: {
          type: 'category',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        polar: {
          center: ['50%', '40%'],
          radius: '100%'
        },
        series: [
          {
            type: 'bar',
            data: [{
              value: value,
              itemStyle: {
                color: this.colorRgb(this.color, 1)
              }
            }],
            coordinateSystem: 'polar',
            roundCap: true,
            barWidth: 10,
            barGap: '-100%',
            z: 2
          },
          { // 小圈
            type: 'bar',
            data: [{
              value: 100,
              itemStyle: {
                color: this.colorRgb(this.color, 0.5),
                shadowColor: this.colorRgb(this.color, 0.6),
                shadowBlur: 5,
                shadowOffsetY: 2
              }
            }],
            coordinateSystem: 'polar',
            roundCap: true,
            barWidth: 5,
            barGap: '-70%',
            z: 1
          }
        ]
      }
    },
    colorRgb (color, opacity) {
      const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
      let color16 = color.toLowerCase()
      if (color16 && reg.test(color16)) {
        if (color16.length === 4) {
          let color16New = '#'
          for (let i = 1; i < 4; i += 1) {
            color16New += color16.slice(i, i + 1).concat(color16.slice(i, i + 1))
          }
          color16 = color16New
        }
        // 处理六位的颜色值
        const color16Change = []
        for (var i = 1; i < 7; i += 2) {
          color16Change.push(parseInt('0x' + color16.slice(i, i + 2)))
        }
        return 'rgba(' + color16Change.join(',') + ',' + opacity + ')'
      } else {
        return color16
      }
    }
  }
}
</script>
