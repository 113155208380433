<template>
  <div class="pie-container">
    <img class="bg-picture" :src="getBgPicture">
    <div class="pie-content" :style="getColor">
      <div class="flex align-center justify-center mb-10 white fs-18">
        <count-to class="pie-rate" :decimals="2" :endVal="value" :duration="2000"/>%
      </div>
      <!-- suffix="%" -->
      <span class="pie-name">{{name}}</span>
    </div>
  </div>
</template>

<script>
import CountTo from 'vue-count-to'
export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    name: {
      type: String,
      default: '-'
    }
  },
  computed: {
    getBgPicture () {
      const primaryUrl = require('@/assets/imgs/common/pie-primary-bg.png')
      const warnUrl = require('@/assets/imgs/common/pie-warn-bg.png')
      return this.value > 60 ? primaryUrl : warnUrl
    },
    getColor () {
      const color = this.value > 60 ? '#00F698' : '#FC348F'
      return { color }
    }
  },
  components: {
    CountTo
  }
}
</script>

<style lang="scss" scoped>
.pie-container {
  position: relative;
}
.bg-picture {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.bg-picture,
.pie-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 12px;
  color: #00F698;
}
.pie-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .pie-name {
    transform: scale(.9);
  }
}
</style>
