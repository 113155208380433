<template>
  <chart-extend ref="chartExtent"/>
</template>

<script>
import ChartExtend from './ChartExtend'
export default {
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    },
    color: {
      type: String
    }
  },
  computed: {
    legend () {
      return this.data.map(item => item.name)
    },
    colors () {
      return [this.color, '#3C404C']
    }
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      handler () {
        this.$nextTick(() => {
          const { myChart } = this.$refs.chartExtent
          myChart.setOption(this.getOption())
        })
      }
    }
  },
  methods: {
    getOption () {
      const data = this.data
      const value = data.length > 0 ? data[0].value : '0'
      const name = data.length > 0 ? data[0].name : '-'
      const option = {
        color: this.colors,
        legend: {
          data: this.legend,
          orient: 'vertical',
          bottom: '5%',
          right: 'center',
          textStyle: {
            color: '#fff',
            fontSize: 10, // 文字的字体大小。
            lineHeight: 12
          },
          itemGap: 5, // 图例每项之间的间隔。
          itemWidth: 12, // 图例标记的图形宽度。
          itemHeight: 12 //  图例标记的图形高度。
        },
        series: [
          {
            data: this.data,
            type: 'pie',
            radius: ['40%', '50%'],
            center: ['50%', '38%'],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: 'center',
              color: '#4c4a4a',
              formatter: '{total|' + value + '%}' + '\n' + '{active|' + name + '}',
              rich: {
                total: {
                  fontSize: 12,
                  fontFamily: '微软雅黑',
                  lineHeight: 18,
                  color: '#fff'
                },
                active: {
                  fontFamily: '微软雅黑',
                  fontSize: 11,
                  color: '#fff'
                }
              },
              emphasis: { // 中间文字显示
                show: true
              }
            },
            lableLine: {
              show: false,
              emphasis: { show: true },
              tooltip: { show: false }
            }
          }
        ],
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c}%',
          backgroundColor: 'rgba(255,255,255,.8)', // 通过设置rgba调节背景颜色与透明度
          color: '#fff'
        }
      }
      return option
    }
  },
  components: { ChartExtend }
}
</script>
