<template>
  <div class="chart-bar-box">
    <chart-extend ref="chartExtent" />
    <div class="title-line">
      <span class="prefix-title" />
      {{ title }}
    </div>
    <span
      v-if="unit"
      class="unit-line"
    >单位：{{ unit }}</span>
  </div>
</template>

<script>
import ChartExtend from './ChartExtend'
export default {
  components: { ChartExtend },
  props: ['title', 'unit', 'data'],
  watch: {
    data: {
      deep: true,
      immediate: true,
      handler (nVal) {
        if (!nVal) return
        this.$nextTick(() => {
          const { myChart } = this.$refs.chartExtent
          myChart.setOption(this.getOption(nVal))
        })
      }
    }
  },
  methods: {
    getOption ({ xAxis, yAxis }) {
      return {
        color: ['#7BA9FA', '#7BA9FA', '#DE9FB1', '#DE9FB1'],
        xAxis: [
          {
            data: xAxis,
            type: 'category',
            axisTick: { show: true },
            axisLine: {
              show: true, // 隐藏X轴轴线
              lineStyle: { color: '#556168' }
            },
            splitLine: {
              show: true,
              lineStyle: { color: '#083049' }
            },
            axisLabel: {
              color: '#fff'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisTick: { show: true },
            axisLine: {
              show: true,
              color: '#556168'
            },
            splitLine: {
              show: true,
              lineStyle: { color: '#083049' }
            },
            axisLabel: {
              color: '#C7C9CA'
            }
          }
        ],
        grid: {
          left: '5%', // 图表距边框的距离
          right: '5%',
          bottom: '3%',
          top: '30%',
          containLabel: true
        },
        series: [
          {
            data: yAxis,
            type: 'bar',
            barWidth: '16',
            label: {
              show: true,
              position: 'top',
              color: '#FFF',
              distance: 10
            },
            itemStyle: {
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                type: 'linear',
                global: false,
                colorStops: [{
                  offset: 0,
                  color: '#5EA1FF'
                },
                {
                  offset: 1,
                  color: '#90BEFF'
                }
                ]
              }
            }
          },
          {
            data: yAxis.map(() => 1),
            type: 'pictorialBar',
            symbol: 'diamond',
            symbolOffset: [0, '50%'],
            symbolSize: ['15', '10']
          },
          {
            data: yAxis,
            type: 'pictorialBar',
            symbolPosition: 'end',
            symbol: 'diamond',
            symbolOffset: [0, '-52%'],
            symbolSize: ['15', '10'],
            zlevel: 2
          }
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' },
          formatter: '{b}：{c}' + (this.unit || '')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chart-bar-box {
  position: relative;
}
.chart-bar {
  width: 100%;
  height: 100%;
}
.title-line {
  position: absolute;
  top: 10%;
  left: 3%;
  color: #fff;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .prefix-title {
    display: block;
    width: 6px;
    height: 6px;
    background: #00ffff;
    border-radius: 50%;
    margin-right: 5px;
  }
}
.unit-line {
  position: absolute;
  width: 70px;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  color: #c7c9ca;
  font-size: 12px;
}
</style>
