<template>
  <chart-extend ref="chartExtent"/>
</template>

<script>
import * as echarts from 'echarts'
import ChartExtend from './ChartExtend'
export default {
  props: ['name', 'value'],
  watch: {
    value: {
      immediate: true,
      handler (nVal) {
        this.$nextTick(() => {
          const { myChart } = this.$refs.chartExtent
          myChart.setOption(this.getOption(nVal))
        })
      }
    }
  },
  methods: {
    getOption () {
      return {
        series: [{
          radius: '90%',
          min: 0,
          max: 100,
          splitNumber: 10,
          type: 'gauge',
          detail: {
            color: '#05FCFF',
            fontSize: 20,
            fontWeight: 'normal',
            offsetCenter: [0, '55%'],
            formatter: '{value}%' // 格式化函数或者字符串
          },
          data: [{
            value: this.value,
            name: this.name
          }],
          pointer: { // 仪表盘指针。
            show: true, // 是否显示指针,默认 true。
            length: '70%', // 指针长度，可以是绝对数值，也可以是相对于半径的百分比,默认 80%。
            width: 5
          },
          itemStyle: { // 仪表盘指针样式。
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: '#636E92'
            }, {
              offset: 0.9,
              color: '#BCC2D4'
            }], false)
          },
          title: {
            offsetCenter: [0, '85%'],
            fontSize: 12,
            color: '#fff'
          },
          axisTick: {
            distance: -11,
            lineStyle: {
              color: '#000'
            }
          },
          splitLine: {
            length: 12, // 分隔线线长。支持相对半径的百分比,默认 30。
            distance: -11,
            lineStyle: {
              color: '#000',
              width: 1
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 12,
              color: [[0.2, '#DF3562'], [0.8, '#003AFE'], [1, '#60BB59']]
            }
          },
          axisLabel: {
            color: '#B4BCC8',
            distance: 20,
            fontSize: 10
          }
        }]
      }
    }
  },
  components: { ChartExtend }
}
</script>

<style>

</style>
